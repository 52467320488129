<template>
  <div class="page-wrapper auth-wrapper">
    <div class="auth-reg-container">
      <div class="auth-reg-container-inner">
        <div class="bubble bubble-one" />
        <div class="bubble bubble-two" />
        <div class="bubble bubble-three" />
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';

@Component
export default class AuthLayout extends SequoiaComponent {}
</script>

<style lang="scss" src="src/styles/layouts/auth-layout.scss"></style>
