<template>
  <AuthLayout>
    <LogoSequoia />
    <div class="registration promocodes">
      <h4 v-html="getTranslation('ktpromo_enter_promocode')" />
      <div
        v-if="!promocodeSent"
        class="text mb-16"
        v-html="getTranslation('ktpromo_get_subscription')"
      />
      <div v-if="!promocodeSent">
        <InputText
          v-model="phone"
          input-category="floating"
          name="phone"
          type="tel"
          size="medium"
          required
          :label="getTranslation('phone_number_reg')"
          :status="incompletePhone ? 'error' : undefined"
          :message="incompletePhone ? getTranslation('incomplete_phone_reg') : ''"
        />
        <InputText
          v-model="promocode"
          input-category="floating"
          size="medium"
          required
          class="mb-16"
          :label="getTranslation('ktpromo_promocode')"
          :status="promocode.length && error ? 'error' : undefined"
          :message="error ? error : ''"
        />
        <ButtonDefault
          class="mb-16"
          :disabled="isSubmitPromocodeButtonDisabled"
          @click="
            sendPromocode().finally(() => {
              sendPromocodePending = false;
            })
          "
        >
          <span v-html="getTranslation('next_reg')" />
        </ButtonDefault>
      </div>

      <div v-if="promocodeSent" class="description">
        <div class="message" v-html="getTranslation('ktpromo_thankyou')" />
        <ButtonDefault href="/">
          <span v-html="getTranslation('ktpromo_to_website')" />
        </ButtonDefault>
      </div>
    </div>
    <LoaderSpinner v-if="sendPromocodePending" />
  </AuthLayout>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import AuthLayout from 'src/layouts/AuthLayout.vue';
import LogoSequoia from 'src/components/auth/parts/LogoSequoia.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';

@Component({
  components: {
    AuthLayout,
    LogoSequoia,
    LoaderSpinner,
    ButtonDefault,
    InputText,
  },
})
export default class PromocodePage extends mixins(SequoiaComponent) {
  phone = '';
  promocode = '';
  promocodeSent = false;
  error? = '';
  sendPromocodePending = false;

  @Watch('promocode')
  onPromocodeChange(newValue: string, oldValue: string) {
    if (newValue === '' || (this.error && newValue !== oldValue)) {
      this.error = '';
    }
  }

  get incompletePhone() {
    return this.$store.incompletePhone;
  }

  get isSubmitPromocodeButtonDisabled() {
    return this.$store.incompletePhone || !this.promocode.length;
  }

  async sendPromocode() {
    this.sendPromocodePending = true;
    const result = actions.siteConfig.savePromocodesKT(
      this.$store,
      {
        phone: this.phone.replace(/[^0-9]/g, ''),
        code: this.promocode.toUpperCase(),
      },
      this.getTranslation('ktpromo_used_promocode'),
      this.getTranslation('ktpromo_wrong_promocode')
    );
    this.promocodeSent = result.ok;
    this.error = result.error;
  }
}
</script>

<style lang="scss" scoped>
.promocodes {
  .text {
    font-size: 14px;
  }
}

.description .message {
  margin: 24px 0;
}
</style>
